.sub-text {
  display: block;
  font-size: 13px;
  color: #364a63;
  font-weight: bolder;
}

table {
  width: 100%;
}

th {
  /* border-right: 2px solid #006e61; */
  text-align: left;
  padding: 0 20px 0 20px;
}

th,
td {
  text-align: left;
  padding: 10px 20px;
  position: relative;
}

.resizer {
  position: absolute;
  right: -2.5px;
  top: 15%;
  bottom: 15%;
  width: 2px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  border-radius: 10px;
  z-index: 99 !important;
  background-color: #dbdfeae6;
  transition: all 0.2 ease-in;
}

.resizer.isResizing {
  background-color: #dbdfeae6;
  opacity: 1;
}

/* td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

/* .text-ellipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.ellipsis span {
  position: absolute;
  left: 5px;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.tableData p {
  padding: 0;
  overflow: hidden;
  position: relative;
  /* display: inline-block; */
  /* text-align: left; */
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-body {
  overflow: auto;
  max-height: calc(100vh - 22rem);
}

.table-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

.table-body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

tr.table-head-row {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.21);
}
