.react-datepicker__navigation:focus,
.react-datepicker__navigation:focus-visible {
  outline: none !important;
  display: flex !important;
}
/* .react-datepicker-popper {
  position: fixed !important;
} */

.SortableItem .form-group .custom-radio {
  margin-right: 10px;
  margin-bottom: 10px;
}
.SortableItem .image-upload-container .image-upload-control .btn .fas {
  margin-right: 10px;
}
.SortableItem .form-group .custom-checkbox {
  margin-right: 10px;
}
.custom-checkbox {
  margin-right: 10px;
  margin-top: 10px;
}

.control-label {
  margin-right: 10px;
  color: black;
}

.react-form-builder-form .rfb-item img {
  width: 10% !important;
}

.SortableItem .row .col-md-2 div {
  min-width: 0px !important;
  margin-bottom: 10px;
}

.SortableItem .row .col div {
  min-width: 0px !important;
  margin-bottom: 10px;
}

.SortableItem .row .col-md-3 div {
  min-width: 0px !important;
  margin-bottom: 10px;
}
.SortableItem .row .col-md-4 div {
  min-width: 0px !important;
  margin-bottom: 10px;
  /* height: 100% !important; */
}

.SortableItem .row .col-md-6 div {
  min-width: 0px !important;
  margin-bottom: 10px;
}
.react-form-builder-toolbar {
  bottom: -56px !important;
  border-top: none !important;
  margin-bottom: 78px;
}

/* .react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
  -webkit-transform: translate3d(350px, 559px, 0px) !important;
  transform: translate3d(350px, 559px, 0px) !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
  transform: translate3d(350px, 394px, 0px) !important;
}

@media screen and (max-width: 600px) {
  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 10px;
    transform: translate3d(38px, 492px, 0px) !important;
  }

  .react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 10px;
    transform: translate3d(38px, 670px, 0px) !important;
  }
} */

.image-upload-control > .btn {
  border: 1px solid black;
  background-color: #006e61;
  cursor: pointer;
  color: white;
  padding: 2px 3px;
  font-size: x-small;
}
.btn-image-clear {
  border: 1px solid black;
  background-color: #006e61;
  cursor: pointer;
  color: white;
  padding: 2px 3px !important;
  font-size: x-small;
}

.btn-file-upload-clear {
  border: 1px solid black;
  background-color: #006e61;
  cursor: pointer;
  color: white;
  padding: 2px 3px !important;
  font-size: x-small;
}

.image-upload {
  cursor: pointer;
}
.sidebar-width {
  width: 74px !important;
}
.expand-sidebar:hover {
  width: 290px !important;
}

.react-select__value-container {
  margin-right: 30px;
}

.test-editor ol{
  list-style: decimal !important ;
}

.test-editor  ul{
  list-style:  inherit !important ;
}

.test-editor ul strong{
  font-weight: bold !important;
}

.test-editor ol strong{
  font-weight: bold !important;
}
.test-editor p strong{
  font-weight: bold !important;
}

.ql-editor ul strong{
  font-weight: bold !important;
}

.ql-editor ol strong{
  font-weight: bold !important;
}

.ql-editor p strong{
  font-weight: bold !important;
}

.reactgrid{
  margin-bottom: 40%;
}

/* .react-quill .form-control{
  height: auto;
  border: none;
} */

.noWrap {
 display: block;
 width: max-content;
}

.wrapField{
  white-space: wrap !important;
}

.ellipsisText {
    overflow: "hidden";
    text-overflow: "ellipsis";
    white-space: "nowrap";
    cursor: pointer;
}


.validation-error:last-child {
  position: fixed;
  top: 10%;
  width: 60%;
  left: 36%;
}

.validation-error > div:first-child {
  overflow-y: scroll;
  height: 100px;
  line-height: 1.8;
}

.validation-error > .clearfix {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-form-builder-form > form > .btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 50px;
}

.table-body {
  overflow: auto;
  max-height: calc(100vh - 22rem);
}

.nk-tb-item.nk-tb-head {
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #fff; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
}